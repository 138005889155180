// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
#app {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    #app-container {
        height: 100vh;
        width: 100vw;
        padding: 0;
        margin: 0;
        top: 0;
        position: absolute;
        background-color: $body-bg;
        .announcements {
            margin: 0.5em 0em;
        }
        &>.row {
            display: flex;
            height: 100vh;
            margin: 0;
            #sidebar {
                display: flex;
                flex-direction: column;
            }
            .inner-left-container,
            .inner-right-container {
                display: inline-block;
                padding-top: 5em;
                padding-bottom: 2em;
                overflow-y: scroll;
                background-color: $body-bg;
            }
            .inner-left-container {
                padding-left: 10px;
                text-align: center;
                .profile_img_sidebar {
                    width: 125px;
                    margin: 1em 0;
                    border-radius: 100%;
                    box-shadow: 0 0 5px #82888f;
                }
                .sidebar-nav {
                    margin-top: 2em;
                    a, a:active {
                        text-align: left;
                        display: block;
                        text-decoration: none;
                        border-radius: 5px;
                        color: $text-color;
                        width: 80%;
                        padding: 1em 2em;
                        margin: auto;
                        margin: 1em auto;
                        background-color: $navbar-default-bg;
                        border: solid 1px;
                        box-shadow: none;
                        border-bottom: solid 2px;
                        font-weight: bold;
                    }
                    a:hover {
                        background-color: $body-bg;
                    }
                }
            }
            .inner-right-container {
                padding-right: 0px;
                padding-left: 15px;
            }
            .nav {
                .nav-tabs {}
            }
        }
        .main_heading {
            border-bottom: 1px solid #eeeeee;
            display: inline-block;
            padding-bottom: 1em;
            margin-bottom: 1em;
        }
        .background_white {
            background: #fff;
        }
        .side_padding_double {
            padding: 0 30px;
            li {
                cursor: pointer;
            }
        }
        .top_padding_double {
            padding-top: 2em;
            padding-bottom: 2em;
        }
        .large_container {
            min-height: 40em;
        }
        .chart_container {
            width: 100%;
            height: 200px;
        }
        .duediligence_form {
            h3 {
                cursor: pointer;
            }
            .panel-collapse {
                padding-left: 15px;
            }
        }
        .mobile_icons {
            display: flex;
            flex-direction: row;
            &>img {
                padding: 0 .5em 0 0;
                &.open_main_sidebar_mobile, &.close_main_sidebar_mobile {
                    cursor: pointer;
                }
            }
        }
    }
    .app_modal_body {
        height: $app-modal-height;
        overflow-y: scroll;
    }
    .register {
        .step-1 {
            display: block;
        }
        .step-2 {
            display: none;
        }
    }
    .logo-text {
        font-weight: bold;
        color: $brand-primary;
        font-size: 2em;
        text-align: center;
        padding-bottom: 1em;
    }
    .drilldown-table {
        margin-top: 2em;
        font-size: medium;
    }
    .forgot_password {
        display: inline-block;
        margin-top: 1em;
        text-align: center;
        color: $text-secondary-color;
        border-bottom: solid 1px;
        color: $text-color;
        &:hover {
            text-decoration: none!important;
        }
    }
    .dropdown-menu {
        a {
            i {
                opacity: 0;
            }
        }
        a.active {
            i {
                opacity: 1;
            }
        }
    }
    .page-header {
        text-decoration: none !important;
        font-weight: bold;
        display: inline-block;
        padding-bottom: 5px;
        border-bottom: solid 0px;
        margin-bottom: 1em;
        margin-top: 1em;
    }
    hr.theme {
        border-color: $text-color;
        margin: 0;
        border-style: dashed;
        border-width: 2px;
    }
    .stat-badge {
        padding: 1em;
        margin: 2em 0;
        width: 50%;
        border-left: solid 2px;
        border-radius: 0;
        background-color: $body-secondary-bg;
        .stat-label {
            margin: 0;
            display: inline-block;
            color: $text-color;
        }
        .stat-count {
            display: inline-block;
            margin: 0;
            margin-left: 1em;
            font-weight: bold;
        }
    }
    a.underlined {
        border-bottom: 1px solid;
    }
    .form-control,
    .input-group-addon {
        color: $navbar-primary;
        border-color: rgba(40, 60, 70, 0.30);
        box-shadow: none;
    }
    .has-success .form-control {
        border-color: $brand-success;
        color: $brand-success;
    }
    .has-warning .form-control {
        border-color: $brand-warning;
        color: $brand-warning;
    }
    .has-error .form-control {
        border-color: $brand-danger;
        color: $brand-danger;
        animation: shake .5s linear;
        -moz-animation: shake .5s linear;
        -webkit-animation: shake .5s linear;
    }
    .g-recaptcha > div {
        border: 1px solid transparent;
        border-radius: 4px;
    }
    .has-error-captcha > div {
        border-color: $brand-danger;
        animation: shake .5s linear;
        -moz-animation: shake .5s linear;
        -webkit-animation: shake .5s linear;
    }
    .has-valid-captcha > div {
        border-color: $brand-success;
    }
    .nav-pills {
        li.active {}
        li {
            &.active {
                a, a:hover, a:focus {
                    color: #fff;
                    background-color: $navbar-primary;
                }
            }
            a,
            a:hover,
            a:focus {
                background-color: #fff;
                color: $navbar-primary;
                margin-bottom: 1em;
                margin-right: 1em;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
                border-radius: 0.6rem;
            }
        }
    }
    .nav-tabs {
        border-color: rgba(40, 60, 70, 0.70);
        li.active {}
        li {
            &.active {
                a, a:hover, a:focus {
                    font-weight: bold;
                    background-color: #fff;
                    border-color: rgba(40, 60, 70, 0.70);
                    border-bottom-color: #fff;
                    box-shadow: 0px 2px #ff7f00 inset;
                }
            }
            a,
            a:hover,
            a:focus {
                color: $navbar-primary;
                margin-right: 1em;
            }
            a:hover {
                border-color: #fff;
                border-bottom-color: $navbar-primary;
                background: #fff;
            }
        }
    }
    .navbar-toggle {
        background-color: #fff;
        border-color: #fff;
        span.icon-bar {
            background-color: $navbar-primary;
        }
    }
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        border-color: #dae2e9;
    }
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(40, 60, 70, 0.30);
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(40, 60, 70, 0.30);
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(40, 60, 70, 0.30);
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: rgba(40, 60, 70, 0.30);
    }
    .btn {
        border-bottom-width: 2px;
        font-weight: bold;
        padding: 0.5em 1.5em;
    }
    a {
        >label {
            text-decoration: none;
        }
    }
    .navbar {
        .container {
            width: 100vw;
        }
        .navbar-header {
            display: block;
            text-align: center;
            padding: 0;
            a {
                margin: 0;
                display: block;
                width: 100%;
            }
        }
        .organization_logo {
            height: 3em;
            margin: 0.5em 15px;
        }
    }
    .background_white {
        background: #fff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        border-radius: 0.6rem;
    }
    .navbar-default .navbar-nav > li > a {
        color: $navbar-primary;
        line-height: 2em;
        font-weight: bold;
        margin: 14px;
        padding: 0;
    }
    .btn-groups-row {
        margin-top: 2em;
        display: inline-block;
        border: 1px solid #ccc;
        border-bottom: 2px solid #ccc;
        border-radius: 4px;
        .btn, .btn:hover, .btn:active {
            border: none;
            background: none;
            box-shadow: none;
            padding: 0.5em 0.75em;
        }
    }
    .mobile_sidebar_btn {
        margin-top: 1em;
    }
    .active {
        display: block !important;
    }
    .due_diligence_document_upload {
        text-align: center;
    }
    .duediligence_project_table {
        max-width: none;
        width: 150%;
    }
    .duediligence_uploaded_document_list {
        list-style: none;
        padding-left: 0px;
        li {
            font-size: 1.2em;
            line-height: 1em;
            margin-bottom: 1em;
            padding: 5px;
            .remove_document {
                width: 1em;
            }
        }
    }
    .error_text {
        color: #f00
    }
    .nav-tabs {
        margin: 0 -15px;
        padding: 0 15px;
        .li > a {
            &:hover, &:focused {
                border-color: #fff #fff #ddd;
                background-color: #fff;
            }
        }
    }
    .clear_search_filters {
        font-size: 1.2em;
        font-weight: 600;
        cursor: pointer;
        display: inline-block;
        .reset-icon {
            width: 18px;
            height: 18px;
            padding: 1px;
            margin-right: 3px;
            color: #fff;
            text-align: center;
            background-color: #6a737d;
            border-radius: 3px;
            margin-bottom: -3px;
        }
    }
    .due_diligence_form_content {
        font-size: 1.2em;
        .tab-content {
            margin-top: 1em;
        }
    }
    .datetimepicker {
        display: flex;
        flex-direction: row;
        & >div {
            width: 89%;
        }
        .input-group-addon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            width: 11%;
        }
    }
    @-webkit-keyframes shake {
        8%, 41% {
            -webkit-transform: translateX(-10px);
        }
        25%,
        58% {
            -webkit-transform: translateX(10px);
        }
        75% {
            -webkit-transform: translateX(-5px);
        }
        92% {
            -webkit-transform: translateX(5px);
        }
        0%,
        100% {
            -webkit-transform: translateX(0);
        }
    }
}

.internet_connection_bar {
    position: absolute;
    top: -30px;
    z-index: 100;
    width: 100%;
    text-align: center;
    padding: 10px;
    display: none;
    &.show {
        display: block;
        top: 0;
        transition: .2s top;
        color: #fff;
    }
    &.disconnected {
        background: red;
        transition: .2s background;
    }
    &.trying {
        background: orange;
        transition: .2s background;
    }
    &.connected {
        background: green;
        transition: .2s background;
    }
}

.radio_div {
    padding-top: 7px;
    div {
        display: inline-block;
    }
}

.sidebar-bg {
    padding: 2em 0;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 1em;
}

@media only screen and (max-width: 768px) {
    .mobile_sidebar_btn {
        float: left;
        width: 27px;
    }
    .navbar-header a {
        width: inherit !important;
        float: none !important;
        display: inline-block !important;
    }
    #app #app-container > .row .inner-right-container {
        padding-right: 0;
        padding-left: 0px;
    }
    .header_right_btns {
        margin-top: 57px !important;
    }
    .due_diligence_document_upload {
        text-align: left !important;
    }
    .due_diligence_document_download {
        text-align: left !important;
    }
    #app-container {
        padding-left: 1em!important;
        padding-right: 1em!important;
    }
    li.dropdown {
        display: block;
        > a {
            display: inline-block;
        }
    }
}

@media only screen and (min-width: 769px) {
    #sidebar {
        display: flex !important;
    }
}

.capture-assessment-container {
    .selected-filters {
        box-shadow: none;
        border: none;
        padding: 10px;
    }
}

.capture-assessment-form {
    .question-block {
        margin-top: 18px;
        .question-title {
            font-size: 16px;
        }
    }
    .answer-block {
        margin-right: 15px;
        display: block;
    }
    .attribute-item {
        margin-bottom: .5em;
    }
    .answer-block-parent {
        display: block;
    }
    .attribute {
        font-size: 1.2em;
        font-weight: normal;
    }
    .attribute-children {}
    .parameter {
        margin-bottom: 1em;
        margin-top: 2em;
        padding-bottom: 1em;
        border-bottom: 1px solid #4d4d4d;
        .form-control:not(textarea) {
            height: 2em !important;
            max-width: 20em;
        }

        .question_text_area {
            max-width: 32em !important;
            max-height: 10em !important;
        }

    }
    
    .form-emphasis {
        font-weight: 600;
    }
}

.beneficiaries-listing-container {
    .form-status {
        margin-left: 6px;
        color: $brand-primary;
        background: transparent;
        border: none;
        display: inline;
        line-height: 1;
        padding: 0;
        font-size: 14px;
        outline: none;
        &.conduct-assessment-btn {
            font-size: 10px;
            margin-top: 3px;
            color: red;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .form-block {
        display: flex;
        align-items: center;
    }
}

#app {
    .applied-filters-block {
        padding-left: 0;
        background-color: transparent;
        li {
            cursor: default !important;
        }
        li + li::before {
            content: '\2192';
            padding: 0 10px;
            color: #777;
        }
    }
}

#new_beneficiary_form,
#beneficiaries_selection_form {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.field-required {
    position: relative;
    &:after {
        content: ' *';
        color: red;
        font-weight: bold;
        font-size: 20px;
        line-height: 1;
        position: absolute;
        margin-top: 2px;
        margin-left: 2px;
    }
}

.item_row {
    padding-top: 15px;
}

.sl-network-container {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
    .feature_item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 5em;
        margin-top: 1em;
        text-align: center;
        padding-right: 0px;
    }
    .background_grey {
        background-color: #dedede;
    }
    .feature_name {
        font-size: 1.5em;
        color: black;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        line-height: 1;
    }
    .main_title {
        margin: 0px;
    }
    .feature_item {
        padding: 0;
        text-align: left
    }
    .dropdown-menu {
        width: 100%;
        right: 0;
        left: auto;
        font-size: 16px;
        line-height: 1;
        padding: 0;
        margin: 12px 0 0;
        li {
            height: 45px;
            font-size: 16px;
            line-height: 1;
            display: flex;
            align-items: center;
            a {
                padding: 0 40px;
                width: 100%;
                &:hover {
                    background-color: transparent;
                }
            }
            &:not(:last-child) {
                border-bottom: 1px solid #F5F5F5;
            }
        }
        .dropdown-header {
            color: #9A000D;
            background-color: #F4F4F4;
            height: 36px;
            padding: 0 40px;
            cursor: initial;
            text-transform: uppercase;
        }
        .custom-dropdown-header {
            background-color: #fff;
            font-size: 24px;
            justify-content: center;
            height: 60px;
            text-transform: capitalize;
        }
    }
}

.btn-primary {
    background-color: #c30012 !important;
    border-color: #c30012 !important;
}

.ngo-logo-right {
    width: 30%;
    margin-top: 1.5em;
}

.sortable th {
    cursor: pointer;
}

.sortable th.descend:after {
    content: "\25B2";
}

.sortable th.ascend:after {
    content: "\25BC";
}

.red-tooltip + .tooltip > .tooltip-inner {
    background-color: #f00;
}

.red-tooltip + .tooltip > .tooltip-arrow {
    border-bottom-color: #f00;
}

@media only screen and (min-width: 768px) {
    .sl-network-container {
        .feature_item {
            margin-right: 0.3em;
            margin-left: 0.3em;
        }
        .dropdown-menu {
            width: 310px;
        }
    }
    .answer-block-parent {
        padding-left: 1em;
    }
    .attribute {
        padding-left: 2em;
    }
    .attribute-children {
        padding-left: 2em;
    }
}

@media only screen and (min-width: 992px) {
    .sl-network-container {
        .feature_item {
            margin-right: 0em;
            margin-left: 1em;
        }
    }
}

.html2canvas-container {
    width: 3000px !important;
    height: 1211em !important;
}

[v-cloak] { display: none; }